<template>
  <DelayHydration>
    <template v-if="isDefaultView">
      <HeaderMain
        :home-link="homeLink"
        :theme-color="themeColor"
        :translation="translations[translationKey]"
      >
        <template #desktop-featured>
          <NavDesktop
            :navigation="featuredNavigation"
            :button-navigation="formattedButtonNavigation"
            :social-navigation="socialNavigation"
            :locale-navigation="localeNavigation"
            :translation="translations[translationKey]"
          />
        </template>
        <template #desktop-minor>
          <NavDesktopMinor :navigation="minorNavigation" />
        </template>

        <template #mobile>
          <NavMobile
            :navigation="featuredNavigation"
            :minor-navigation="minorNavigation"
            :button-navigation="formattedButtonNavigation"
            :social-navigation="socialNavigation"
            :locale-navigation="localeNavigation"
            :home-link="homeLink"
            :locale="translationKey"
            :translation="translations[translationKey]"
          />
        </template>
      </HeaderMain>
    </template>

    <template v-else>
      <HeaderLegacy
        :home-link="homeLink"
        :theme-color="themeColor"
        :translation="translations[translationKey]"
      >
        <template #desktop>
          <NavDesktopLegacy
            :main-navigation="mainNavigation"
            :button-navigation="formattedButtonNavigation"
            :social-navigation="socialNavigation"
            :locale-navigation="localeNavigation"
            :translation="translations[translationKey]"
          />
        </template>

        <template #mobile>
          <NavMobileLegacy
            :main-navigation="mainNavigation"
            :button-navigation="formattedButtonNavigation"
            :social-navigation="socialNavigation"
            :locale-navigation="localeNavigation"
            :home-link="homeLink"
            :translation="translations[translationKey]"
          />
        </template>
      </HeaderLegacy>
    </template>
  </DelayHydration>
</template>

<script setup lang="ts">
import type { T3Link } from '@t3headless/nuxt-typo3'
import { computed, defineAsyncComponent } from 'vue'

import { useCurrentLocale, useT3Options } from '#imports'

import type { HeaderComponentProps } from '~/types'
import type { UiButtonProps } from '~ui/types'

import translations from './translations.json'

const translationKey = useCurrentLocale<typeof translations>()

const HeaderMain = defineAsyncComponent(
  () => import('~ui/components/Base/HeaderMain')
)
const NavDesktop = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavDesktop')
)
const NavDesktopMinor = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavDesktop/Minor')
)
const NavMobile = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavMobile')
)

// Legacy
const HeaderLegacy = defineAsyncComponent(
  () => import('~ui/components/Base/HeaderMain/Legacy')
)
const NavDesktopLegacy = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavDesktop/Legacy')
)
const NavMobileLegacy = defineAsyncComponent(
  () => import('~ui/components/Ui/Navigation/NavMobile/Legacy')
)

const { options } = useT3Options()

const props = defineProps<HeaderComponentProps>()

const isDefaultView = computed(() => {
  if (options.api.baseUrl?.includes('t3headless')) return false
  return !!props.featuredNavigation.filter((link) => !link.spacer).length
})

const mainNavigation = computed(() =>
  props.navigation.filter((link) => !buttonNavigation.value.includes(link))
)
const buttonNavigation = computed(() =>
  props.navigation.filter((link) => link.buttonLabel && !link.children)
)

const formattedButtonNavigation = computed(() => {
  return buttonNavigation.value.map(({ buttonLabel, link, target }) => ({
    content: buttonLabel,
    link: { href: link, target } as T3Link,
    variant: 'solid-typo3-orange',
    noWrap: true
  })) as Array<UiButtonProps>
})
</script>
